import store from '@/store'
import { styles } from '@/components/views/account/map/detail/common/mapStyles'
import chroma from 'chroma-js'
import isMobileDevice from '@/components/utils/mobile/isMobileDevice'
import { getNewObjects, getRemovedObject, readSystemFile } from '@/components/utils/mobile/filesystemInteraction'
import { isNeedOfflineMode } from '@/components/utils/mobile/common'
import { getRadiusPoint } from '@/components/utils/common'

class BaseSelected {
  constructor (props) {
    this.layer = null
    this.type = null
    this.detail = null
    this.children = null
    this.schemas = props && props.hasOwnProperty('schemas') ? props.schemas : null
  }

  select (layer, type) {
    this.layer = layer
    this.type = type
    this._selectLayer()
  }

  unselect (isSoftDelete) {
    this._unselectLayer(isSoftDelete)
    this.layer = null
    //this.type = null
    this.detail = null
    this.children = null
    //this.schemas = null
  }

  initChildren (children) {
    this.children = children
  }

  async fillDetail () {
    if (this.layer.feature.properties.id) {
      if (!await isNeedOfflineMode()) {
        const params = { additionalParams: { organization_id: store.getters['auth/getProfile'].organization.id } }
        this.detail = await store.dispatch('server/get', {
          url: this._getUrl() + '/detail/' + this.layer.feature.properties.id,
          params
        })
      } else if (isMobileDevice()) {
        await this._fillDetailMobileOffline()
      }
    } else {
      this.detail = this.layer.feature
    }
    return true
  }

  async _fillDetailMobileOffline () { // здесь обычные детали: properties, geometry, type
    let path = ''
    let pathCopy = '' // для копии файла
    let fileNew = null
    if (this.type === 'passport') {
      path = 'map/passport/' + this.layer.feature.properties.id + '/detail'
      pathCopy = 'map/passport/copies/' + this.layer.feature.properties.id + '/detail'
    } else if (this.type === 'object') {
      path = 'map/passport/' + this.layer.feature.properties.passport_id + '/objects/' + this.layer.feature.properties.id
      pathCopy = 'map/passport/' + this.layer.feature.properties.passport_id + '/objects/copies/' + this.layer.feature.properties.id
      fileNew = (await getNewObjects(this.layer.feature.properties.passport_id))?.find((item) => item?.properties?.id === this.layer.feature.properties.id)
    }
    let detail // детали либо паспорта, либо объекта в зависимости от path (ПОЛУЧЕНИЕ ДЕТАЛЕЙ)
    if (await readSystemFile(pathCopy)) {
      detail = JSON.parse(await readSystemFile(pathCopy))
    } else if (await getRemovedObject(this.layer.feature.properties.passport_id, this.layer.feature.properties.id)) {
      detail = JSON.parse(await getRemovedObject(this.layer.feature.properties.passport_id, this.layer.feature.properties.id))
    } else if (fileNew) {
      detail = fileNew
    } else {
      detail = JSON.parse(await readSystemFile(path))
    }
    this.detail = detail
  }

  _getUrl () {
    return this.type
  }

  _selectLayer () {
    this.layer.setStyle(this._selectedStyle())
  }

  _unselectLayer (isSoftDelete) {
    if (this.layer) {
      const opacity = isSoftDelete ? { opacity: 0.4, fillOpacity: 0.5 } : {
        opacity: 1,
        fillOpacity: this.layer.feature.geometry.type === 'Point' ? 1 : 0.2
      }
      const radius = getRadiusPoint(this.layer._map._zoom)
      this.layer.setStyle({
        ...this._getDefaultStyle(),
        fill: this.layer.feature.geometry.type !== 'LineString',
        ...opacity,
        ...this._getReportStyle(),
        ...this._unSavedStylesObject(),
        weight: this.layer.feature.geometry.type === 'LineString' && isMobileDevice() ? 5 : 2,
        ...this.rememberUnselectStyle(),
        radius
      })
    }
  }

  _getReportStyle () {
    const properties = this.layer.feature.properties
    let result = {}
    if (this.type === 'object') {
      const findReportObject = store.getters['map/inCart'](properties.eav_entity_id, this.layer.feature)
      if (findReportObject) {
        result = { color: chroma(this.schemas[properties.eav_entity_id].style.color).brighten().hex() }
      }
    }
    return result
  }

  _unSavedStylesObject () {
    let result = {}
    if (!this.layer?.feature?.properties.id && this.type === 'object' && !this.layer?.feature?.properties?.style) {
      result = {
        color: '#ffffff',
        fillColor: '#ff00e1'
      }
    } else if (this.type === 'object' && this.layer?.feature?.properties?.style) {
      result = this.layer.feature.properties.style
    }
    return result
  }

  rememberUnselectStyle () { //стиль для последнего выбранного объекта
    const result = {}
    if (this.type === 'object' && this.layer) {
      result.color = '#000000'
      result.fillColor = '#6bfffa'
      store.commit('map/lastSelected', this.layer)
    }
    return result
  }

  _selectedStyle () {
    let style = { ...this._getDefaultStyle(), ...styles.selectedStyle[this.type] }
    if (this.type === 'object') {
      style.color = '#000000'
      style.fillColor = '#6bfffa'
    } else {
      style.color = chroma(style.color).darken().hex()
    }
    return { ...style, ...this._unSavedStylesObject() }
  }

  _getDefaultStyle () {
    return this.layer.feature.properties.style
  }
}

export default BaseSelected
